export default [
  // {
  //   title: "Dashboards",
  //   icon: "HomeIcon",
  //   tag: "2",
  //   tagVariant: "light-warning",

  //   children: [
  //     {
  //       title: "eCommerce",
  //       route: "dashboard-ecommerce",
  //       // acl: {
  //       action: "read",
  //       resource: "ACL",
  //       // },
  //     },
  //     {
  //       title: "Analytics",
  //       route: "dashboard-analytics",
  //       action: "read",
  //       resource: "ACL",
  //     },
  //   ],
  // },

  {
    title: "Home",
    route: "access-control",
    icon: "HomeIcon",
    action: "read",
    resource: "ACL",
  },
  
];
