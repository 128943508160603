export default [
  {
    title: "Logs",
    route: "logs-list",
    icon: "HardDriveIcon",
    action: "view",
    resource: "Log",
  },
  {
    title: "Organization",
    route: "organization-profile",
    icon: "SettingsIcon",
    action: "profile",
    resource: "Organization",
  },
];
