export default [
  {
    title: 'Administrative',
    icon: 'ApertureIcon',
    children: [
      {
        title: "Organization",
        route: "organization-list",
        action: "read",
        resource: "Organization",
        icon: "GlobeIcon",
      },
      {
        title: "Module",
        route: "module-list",
        icon: "DatabaseIcon",
        action: "view",
        resource: "Module",
      },
      {
        title: "Package",
        route: "package-list",
        icon: "PackageIcon",
        action: "view",
        resource: "Package",
      },
    ]
  }
];
